









import { Component, Vue } from 'vue-property-decorator';
import HomePageBaner from '@/components/HomePageBaner/index.vue';
import TeamPage from '@/components/TeamPage/index.vue';// @ is an alias to /src

@Component({
  components: {
    TeamPage,
    HomePageBaner,
  },
})
export default class ProjectsPage extends Vue {
  banerTitle = {
    text: 'Наша команда',
  }

  banerText = 'Можно текст какой-нибудь сюда. Текст текст текст Текст текст текст Текст текст текст Текст текст текст'
}
