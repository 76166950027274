import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ProjectsPageItem extends Vue {
  projects:any = [
    [
      {
        id: 1,
        task: 'Модель прогнозирования спроса и предложения нефти.',
        desc: 'Алгоритмы машинного обучения для прогнозирования спроса и предложения нефти, а также цен на нефть марки BRENT, на основе данных функционирования  экономик 49 стран мира.',
        results: [
          'Исходная информация для участия клиента в переговорах с ОПЕК по корректировке добычи нефти.',
          'Корректировка квот добычи нефти на месторождениях страны.',
          'Планирование бюджета с учетом прогнозов цен на нефть.',
        ],
      }, {
        id: 2,
        task: 'Подсистемы моделирования и прогнозирования производства и потребления по отдельным группам промышленной продукции.',
        desc: 'Модель прогнозирующая развитие товарных рынков страны на 10 лет вперед (547 рынков, более 1500 индикаторов, сценарное прогнозирование).',
        results: [
          'Формирование бюджета государственных программ в различных сферах промышленности.',
          'Разработка балансов спроса-предложения с торговыми партнерами клиента.',
        ],
      }, {
        id: 23,
        task: 'Разработка стратегии цифровой трансформации транспортного комплекса государства.',
        desc: 'Разработка документа государственного стратегического планирования.',
        results: [
          'Разработан перечень основных мероприятий цифрового развития всей транспортной отрасли',
          'Разработаны модели рисков и оценки ущерба',
          'Составлена дорожная карта реализации мероприятий по цифровой трансформации',
        ],
      }, {
        id: 4,
        task: 'Разработка скоринговых моделей для предсказания финансовых проблем предприятий отрасли машиностроения.',
        desc: 'Модели, предсказывающие риски снижения финансовой устойчивости предприятия и оценивающие размер долгов. Мониторинг и контроль состояния 2000 предприятий.',
        results: [
          'Оценка размера субсидий на очередные годы планирования государственной поддержки предприятий.',
          'Улучшенное бюджетное планирование.',
        ],
      }, {
        id: 5,
        task: ' Динамическая стохастическая модель общего равновесия для долгосрочного прогнозирования макроэкономического развития страны.',
        desc: 'DSGE модель для разработки сценарных прогнозов до 2040 года по 49 показателям, включая процентные ставки и индексы цен.',
        results: [
          'Долгосрочное бюджетное планирование: прогноз доходов и расходов.',
          'Прогноз цен на множество товаров и субститутов.',
        ],
      },
    ], [
      {
        id: 6,
        task: 'Модели стресс-тестирования для различных объектов экономики.',
        desc: 'Ансамбль моделей позволяющий отследить путь распространения шоков и отклики всех параметров.',
        results: [
          'Оценка размера субсидий на очередные годы планирования государственной поддержки предприятий.',
          'Бюджетное планирование социальных систем (размеры пенсий, выплат безработным и другие параметры).',
          'Оценка рисков при сценарии стагнации экономики или экономического кризиса.',
          'Оценка потенциального ущерба экономической системы при изменении государственной политики или снижении экзогенных факторов (цены на нефть, курс валюты и др.).',
          'Разработка мер противодействия при реализации рисковых ситуаций.',
        ],
      }, {
        id: 7,
        task: 'Модель внешнеторговых отношений.',
        desc: 'Модель позволяющая определить влияние государственной политики на показатели экспорта и импорта и разработать предложения по принятию решений.',
        results: [
          'Оценка экспортного потенциала потенциальных партнеров (создание экспортных производств для покрытия потребностей в импорте других государств).',
          'Разработка экспортной политики.',
          'Разработка политики замещения импорта.',
          'Разработка прогнозов экспортных и импортных цен.',
          'Оценка влияния различных факторов на показатели экспорта и импорта.',
        ],
      }, {
        id: 8,
        task: 'Система многомерного сравнительного анализа.',
        desc: 'Система представляет собой набор большого числа моделей (по одной для каждого наблюдения системы), учитывающих возможность многомерного вывода (зависимые переменные) и ввода (факторы) для описания состояния объекта.',
        results: [
          'Разработка региональных и локальных стратегий развития объекта.',
          'Системы автоматизированного мониторинга и оценки эффективности объектов (государственные компании, субъекты и регионы страны и др.).',
        ],
      }, {
        id: 9,
        task: 'Планирование поставок и прогнозирование спроса.',
        desc: 'Модель, предсказывающая спрос на продажу одежды и cоставляющая оптимальный план завозов товара в магазины.',
        results: [
          'Снижение потерь из-за случаев нехватки товаров в магазинах на 80% в год.',
          'Среднее снижение количества экстремальных перебросов товаров на 30% в месяц.',
        ],
      }, {
        id: 10,
        task: 'Прогнозирование продаж.',
        desc: 'Модель, предсказывающая спрос на продукты с учётом доходов населения, погоды, праздников и других факторов.',
        results: [
          'Точность прогноза продаж более 80%.',
          'Снижение потерь на скоропортящихся товарах около 42% в месяц.',
          'Снижение длительности отсутствия товаров на полках магазинов.',
          'Снижение затрат на логистику – 5%.',
        ],
      },
    ], [
      {
        id: 11,
        task: 'Планирование продаж и  прогнозирование цен.',
        desc: 'Модель, выявляющая эластичность спроса и подбирающая оптимальные цены в соответствии с оцененными эластичностями.',
        results: [
          'Увеличение прибыльности на 6.2% на выбранной линейке товаров.',
          'Сегментация клиентов на множество подгрупп и детальное определение спроса.',
        ],
      }, {
        id: 12,
        task: 'Прогнозирование и расчет различных акций.',
        desc: 'Модель, выявляющая чистый эффект от программы клиента.',
        results: [
          'Рост продаж клиента за счет обоснованного вычисления экономического эффекта от внедрения программы в магазинах клиента.',
          'Улучшенная система принятия решений',
        ],
      }, {
        id: 13,
        task: 'Автоматизация логистики.',
        desc: 'Модель, осуществляющая автоматический подсчет поступившего товара на основе фото.',
        results: [
          'Ежедневное сокращение времени на подсчет до 4 часов.',
        ],
      }, {
        id: 14,
        task: 'Выявление мошенничества со стороны работников.',
        desc: 'Система автоматического определения случаев мошенничества на основе данных камер. Модель выявления образов на основе обработки видеосигнала.',
        results: [
          'Обработка фотографий с полей в реальном времени.',
          'Выявлено 30 случаев погрузок в чужие авто за половину сезона сбора (экономия - 50 тыс. долларов США).',
          'Выявлено 45 случаев потери урожая в процессе перевозки к весовой (экономия - 75 тыс. долларов США).',
        ],
      }, {
        id: 15,
        task: ' Выявление мошенничества со стороны работников.',
        desc: 'Система автоматического определения случаев мошенничества на основе данных камер. Модель выявления образов на основе обработки видеосигнала.',
        results: [
          'Сокращение временных затрат на обработку фотографии в 20 раз.',
          'Выявлено 20 попыток недочета поступившего груза за 2 месяца (экономия - 40 тыс. долларов США)',
          'Система, позволяющая руководству получать достоверную информацию.',
        ],
      },
    ], [
      {
        id: 16,
        task: 'Предсказание поломок.',
        desc: 'Система предсказывающая сбои и поломки на основе данных производственного оборудования.',
        results: [
          'Сокращение затрат на сырье - 50%.',
          'Программное обеспечение для работы с данными.',
        ],
      }, {
        id: 17,
        task: 'Скоринговая модель коммерческого банка.',
        desc: 'Модель использует банковские и внешние данные о клиенте.',
        results: [
          'Сокращение дефолтов на 12%.',
          'Автоматическое принятие решений.',
        ],
      }, {
        id: 18,
        task: 'Предсказание поломок.',
        desc: 'Модель обнаруживает аномалии в работе транспортных средств в режиме реального времени и на основе данных планирует график ремонтов отдельно для каждой единицы.',
        results: [
          'Сокращение затрат на ремонт парка  - 11%.',
        ],
      }, {
        id: 19,
        task: 'Персонализация и автоматизация email-рассылок.',
        desc: 'Модель выделяет детальные клиентские сегменты со схожими предпочтениями, используя все доступные о клиенте данные. Решение позволяет отправлять персонализированные предложения в оптимальное время.',
        results: [
          'Конверсия в покупки выросла на 12%.',
        ],
      }, {
        id: 20,
        task: 'Автоматизация логистики.',
        desc: 'Приложение в инфраструктуре клиента с визуализацией групп заказов и модель для предварительного распределения заказов.',
        results: [
          'Автоматизация - 80% ручных операций отдела логистики.',
        ],
      },
    ], [
      {
        id: 21,
        task: 'Автоматизация процесса определения объектов.',
        desc: 'Система позволяющая распознавать лица и группировать фотографии.',
        results: [
          'Автоматизация 40% ручного труда.',
        ],
      }, {
        id: 22,
        task: 'Анализ текста.',
        desc: 'Система позволяет анализировать тексты сообщений, группирует все отправляемые сообщения по категориям а также приводит похожие сообщения к шаблонному виду.',
        results: [
          'Сокращение затрат на СМС – рассылки на 26%.',
        ],
      }, {
        id: 3,
        task: 'Оценка рыночной стоимости предприятий машиностроительной отрасли страны.',
        desc: 'Модели автоматизированной оценки рыночной стоимости предприятий на основе данных рыночной, финансовой и налоговой отчетностей. Мониторинг и переоценка 900 предприятий с подготовкой отчета. ',
        results: [
          'Оценка экономического эффекта от продажи национализированных предприятий.',
        ],
      },
    ],
  ];
}
