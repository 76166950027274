










import { Component, Vue } from 'vue-property-decorator';
import HomePageBaner from '@/components/HomePageBaner/index.vue';
import InDevelopment from '@/components/InDevelopment/index.vue';
import ProjectsPageItem from '@/components/ProjectsPageItem/index.vue';// @ is an alias to /src

@Component({
  components: {
    ProjectsPageItem,
    HomePageBaner,
    InDevelopment,
  },
})
export default class ProjectsPage extends Vue {
  banerTitle = {
    text: 'Кейсы',
    span: '',
  }

  // banerTextTop = 'Портфолио'
  banerTextTop = 'Кейсы'

  banerTextBottom = 'Для более детальной информации по проектам и индустриям отправьте запрос на info@naura.ai'
}
